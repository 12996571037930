<template>
  <div class="page">
   <AppHeader />
    <router-view />
    <AppFooter />
  </div>
</template>
<script>
import AppHeader from './components/Header.vue';
import AppFooter from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter
  }
};
</script>
<style>
/* Add responsive spacing for header */
@media (max-width: 991px) {
  .site-main {
    padding-top: 80px; /* Adjust this value based on your header height */
  }
  
  .site-main section:first-child {
    margin-top: 20px;
  }
}
</style>